import React, { useEffect, useState } from "react";
import { CgCloseO } from "react-icons/cg";
import { FaStar } from "react-icons/fa6";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";

const AppDownloadButton = () => {
  const intl = useIntl();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIosDevice = /iphone|ipad|ipod/.test(userAgent);
    const isStandalone =
      window.navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches;

    setIsIos(isIosDevice);
    setIsInStandaloneMode(isStandalone);

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      setDeferredPrompt(null);
    }
  };

  if (isInStandaloneMode || isIos || !isVisible) {
    return null; // Hide the component if the app is in standalone mode or on an iOS device
  }

  return (
    <div>
      {deferredPrompt && (
        <div
          className="d-flex align-items-center justify-content-center w-100 position-fixed bottom-0 start-0 end-0 p-2"
          style={{ zIndex: "99", background: "rgba(0,0,0,0.6)" }}
        >
          <div>
            <img
              src={toAbsoluteUrl("/image/logo/2a.png")}
              alt="Logo"
              style={{ width: "30px", height: "auto" }}
            />
          </div>
          {/* Two rows of words */}
          <div style={{ textAlign: "center", margin: "0 10px" }}>
            <div className="text-font1 ws-nowrap" style={{ display: "flex" }}>
              <div style={{ color: "#f5be09", paddingRight: "5px" }}>
                mm666m
              </div>
              <div style={{ color: "#f5be09" }}>
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </div>
            </div>
            <div className="text-font1 ws-nowrap" style={{ display: "flex" }}>
              <div style={{ color: "white", paddingRight: "5px" }}>
                Official
              </div>
              <div style={{ color: "white", paddingRight: "5px" }}>No Ad</div>
              <div style={{ color: "white", paddingRight: "5px" }}>
                No Plugin
              </div>
              <div style={{ color: "white" }}>Anti-hijacking</div>
            </div>
          </div>
          <div
            className="text-font2 ws-nowrap"
            style={{
              border: "1px solid #f5be09",
              borderRadius: "5px",
              padding: "5px",
              color: "#f5be09",
            }}
            onClick={handleInstallClick}
          >
            {intl.formatMessage({ id: "downloadapp" })}
          </div>
          <div style={{ color: "white", paddingLeft: "5px" }}>
            <CgCloseO onClick={toggleVisibility} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AppDownloadButton;
