import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/SettingAction";
import Skeleton from "react-loading-skeleton";
import { CopyToClipboard } from "../../../../utils/CommonHelper";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { EditPasswordDialog } from "./editPassword/EditPasswordDialog";
import SweetAlert2 from "react-sweetalert2";
import { ChangeRegionDialog } from "./changeRegion/ChangeRegionDialog";
import { useAuth } from "../../../auth/components/AuthInit";
import * as homeActions from "../../../home/redux/HomeAction";

export const Personal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const [swalProps, setSwalProps] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [editCurrency, setEditCurrency] = useState();
  const [isFetchingCurrency, setIsFetchingCurrency] = useState(true);
  const [currencyLists, setCurrencyLists] = useState([]);
  const [isExpand, setIsExpand] = useState(true);
  const { auth, saveAuth } = useAuth();

  const homeState = useSelector(({ home }) => home, shallowEqual);

  useEffect(() => {}, []);

  return (
    <>
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        backdrop={false}
        {...swalProps}
        customClass="custom-swal"
      />
      <ChangeRegionDialog
        setSwalProps={setSwalProps}
        region={editCurrency ? editCurrency.currencycode : ""}
      />
      <div
        style={{
          backgroundColor: "black",
          backgroundImage: `url(${process.env.PUBLIC_URL}/image/homebackground.png)`,
          backgroundSize: "cover",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={styles.navBar}>
          <div style={styles.backButton}>
            <Link to="/setting/profile">
              <FiArrowLeft style={{ color: "white" }} />
            </Link>
          </div>
          <div style={styles.title} className="text-font4">
            {intl.formatMessage({ id: "personal" })}
          </div>
        </div>
        <div>
          <div
            style={{
              backgroundColor: "#2e323a",
              width: "95%",
              margin: "10px auto",
              padding: "5px",
              color: "white",
              fontSize: "12px",
              borderRadius: "5px",
            }}
          >
            {settingState.profile ? (
              <div>
                <div
                  className="text-muted text-font3"
                  style={{ marginLeft: "10px" }}
                >
                  {intl.formatMessage({ id: "personal" })}
                </div>

                {settingState && settingState.profile && (
                  <>
                    <EditPasswordDialog setSwalProps={setSwalProps} />
                    <div
                      style={{
                        backgroundColor: "#1c1e23",
                        width: "95%",
                        margin: "5px auto",
                        padding: "10px",
                        color: "white",
                        fontSize: "12px",
                        borderRadius: "5px",
                        display: "flex",
                      }}
                    >
                      <div className="text-muted" style={{ flex: "3" }}>
                        {intl.formatMessage({ id: "username" })}
                      </div>
                      <div className="text-muted" style={{ flex: "1" }}>
                        {":"}
                      </div>
                      <div style={{ flex: "6" }}>
                        {settingState.profile.username}
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#1c1e23",
                        width: "95%",
                        margin: "5px auto",
                        padding: "10px",
                        color: "white",
                        fontSize: "12px",
                        borderRadius: "5px",
                        display: "flex",
                      }}
                    >
                      <div className="text-muted" style={{ flex: "3" }}>
                        {intl.formatMessage({ id: "password" })}
                      </div>
                      <div className="text-muted" style={{ flex: "1" }}>
                        {":"}
                      </div>
                      <div style={{ flex: "5" }}>
                        {" "}
                        {settingState.profile.password.replace(/./g, "*")}
                      </div>
                      <div style={{ flex: "1" }}>
                        {/* {settingState.profile.registerby === "telno" && (
                          <i
                            className="ms-2 fa fa-edit cursor-pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#passwordModal"
                          />
                        )} */}
                        <i
                          className="ms-2 fa fa-edit cursor-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#passwordModal"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#1c1e23",
                        width: "95%",
                        margin: "5px auto",
                        padding: "10px",
                        color: "white",
                        fontSize: "12px",
                        borderRadius: "5px",
                        display: "flex",
                      }}
                    >
                      <div className="text-muted" style={{ flex: "3" }}>
                        {intl.formatMessage({
                          id:
                            settingState.profile.registerby === "telno"
                              ? "phoneNo"
                              : "email",
                        })}
                      </div>
                      <div className="text-muted" style={{ flex: "1" }}>
                        {":"}
                      </div>
                      <div style={{ flex: "6" }}>
                        {settingState.profile.telnoemail}
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#1c1e23",
                        width: "95%",
                        margin: "5px auto",
                        padding: "10px",
                        color: "white",
                        fontSize: "12px",
                        borderRadius: "5px",
                        display: "flex",
                      }}
                    >
                      <div className="text-muted" style={{ flex: "3" }}>
                        {intl.formatMessage({ id: "refferalCode" })}
                      </div>
                      <div className="text-muted" style={{ flex: "1" }}>
                        {":"}
                      </div>
                      <div style={{ flex: "5" }}>
                        {settingState.profile.referralcode}
                      </div>
                      <div style={{ flex: "1" }}>
                        <i
                          className="ms-2 fa fa-copy cursor-pointer"
                          style={{ color: "white" }}
                          onClick={() => {
                            if (
                              sessionStorage.getItem("platform") === "mobile"
                            ) {
                              console.log(
                                `clipboard: ${settingState.profile.referralcode}`
                              );
                              CopyToClipboard(
                                settingState.profile.referralcode,
                                setSwalProps,
                                intl
                              );
                            } else {
                              CopyToClipboard(
                                settingState.profile.referralcode,
                                setSwalProps,
                                intl
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#1c1e23",
                        width: "95%",
                        margin: "5px auto",
                        padding: "10px",
                        color: "white",
                        fontSize: "12px",
                        borderRadius: "5px",
                        display: "flex",
                      }}
                    >
                      <div className="text-muted" style={{ flex: "3" }}>
                        {intl.formatMessage({ id: "registerDate" })}
                      </div>
                      <div className="text-muted" style={{ flex: "1" }}>
                        {":"}
                      </div>
                      <div style={{ flex: "6" }}>
                        {settingState.profile.registerdate}
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <Skeleton className="w-100" height="50px" />
            )}
          </div>
          <div
            style={{
              backgroundColor: "#2e323a",
              width: "95%",
              margin: "10px auto",
              padding: "5px",
              color: "white",
              fontSize: "12px",
              borderRadius: "5px",
            }}
          >
            {settingState.profile ? (
              <div
                className="d-flex align-items-center text-font2"
                style={{ width: "95%", margin: "0 auto" }}
              >
                <div
                  style={{ width: "100%", margin: "0 auto" }}
                  className="text-font2"
                >
                  {!isFetchingCurrency ? (
                    <>
                      <div className="dropdown">
                        <button
                          className={`form-control text-start d-flex align-items-center text-font2${
                            selectedCurrency ? "" : "text-placeholder"
                          }`}
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-text={intl.formatMessage({ id: "currency" })}
                        >
                          {selectedCurrency ? (
                            <>
                              <img
                                src={selectedCurrency.imageurl}
                                alt={selectedCurrency.currencycode}
                                className="me-4 currency-icon"
                              />
                              <div>{selectedCurrency.currencyname}</div>
                            </>
                          ) : (
                            intl.formatMessage({ id: "currency" })
                          )}
                        </button>
                        <div
                          className="dropdown-menu"
                          style={{ width: "100%", margin: "0 auto" }}
                        >
                          {currencyLists
                            .filter(
                              (currency) =>
                                currency.currencycode !== auth.currencycode
                            )
                            .map((currency) => (
                              <div
                                className={`dropdown-item d-flex align-items-center py-2 cursor-pointer text-font2 ${
                                  selectedCurrency &&
                                  selectedCurrency.currencycode ===
                                    currency.currencycode
                                    ? "active"
                                    : ""
                                }`}
                                style={{ color: "white" }}
                                data-bs-toggle="modal"
                                data-bs-target="#regionModal"
                                key={currency.currencycode}
                                onClick={() => {
                                  setEditCurrency(currency);
                                  document
                                    .querySelector(".dropdown-menu")
                                    .classList.remove("show");
                                }}
                              >
                                <img
                                  src={currency.imageurl}
                                  alt={currency.currencycode}
                                  className="me-4 currency-icon"
                                />
                                <div style={{ color: "white" }}>
                                  {currency.currencyname}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <Skeleton className="w-100" height="35px" />
                  )}
                </div>
              </div>
            ) : (
              <Skeleton className="w-100" height="50px" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};
