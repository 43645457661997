import axios from "axios";
import { projectid } from "../../common/redux/ApiClient";

const baseUrl = "http://35.240.204.237/es3MemberApi";
const domain = "mm666m";

export const LOGIN_URL = `${baseUrl}/login`;

export const getCurrentCountryCode = (ipAddress) => {
  return axios
    .get(`https://ipapi.co/${ipAddress}/json/`, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const login = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1.ashx`,
      data: { ...queryParams, projectid },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};
export const loginChoose = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member5.ashx`,
      data: { ...queryParams, projectid },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const addLoginAcc = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member5.ashx`,
      data: { ...queryParams, projectid },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const fetchIPAddress = () => {
  return axios
    .get(`${window.location.protocol}//api.${domain}.com/clientIP.php`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const fetchVerificationCode = () => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/VerificationCode`,
      data: {},
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const getOTP = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/PhoneRegisterOtp`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const fetchLogoBackground = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/MemberMobile1/MbLoginRegisterPageUI`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};
export const fetchCurrencyList = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/CurrencyList`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//start : register

export const register = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/PhoneRegisterEnterOtp`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const registerStep2 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/PhoneRegisterEnterOtp`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const registerStep3 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/PhoneRegisterFinal`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//end : register

//start : phone authentication

export const phoneAuthStep1 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/MemberPhoneRequestOtp`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const phoneAuthStep2 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/MemberIDVerifyEnterOtp`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//end : phone authentication

//start : forgot password

export const forgotPasswordStep1 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/ForgetPasswordPhone`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const forgotPasswordStep2 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/ForgetPasswordCheckOtp`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const forgotPasswordStep3 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member1/ResetPasswordOtpPhone`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//end : forgot password
//start : gmailforgot password

export const gmailForgotPasswordStep1 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member2/ForgetPasswordEmail`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const gmailForgotPasswordStep2 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member2/ForgetPasswordEmailCheckOtp`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const gmailForgotPasswordStep3 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member2/ResetPasswordOtpEmail`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//end : gmail forgot password
//start : google authentication

export const googleRegister = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member2/SocialMediaRegister`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const googleLogin = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/Member2/SocialMediaLogin`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//end : google authentication

export const fetchLoginSlide = (language) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/index.php`, {
      url: `${baseUrl}/MemberPc1/PcLoginRegisterPageUI`,
      data: { language },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};
export const fetchPromoUrl = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//api.${domain}.com/getApi.php`, {
      url: `${baseUrl}/Member8/PromoDialogUrl?${queryParams}`,
      data: {},
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};
export const getUserData = () => {
  const data = localStorage.getItem("UserData");
  if (!data) {
    return;
  }

  try {
    const auth = JSON.parse(data);
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};
